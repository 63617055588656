import React from "react";
import { SocialIcon } from "react-social-icons";
import { Container } from "react-bootstrap";

const MyFooter = () => {
  return (
    <Container className="myfooterParent container-1440 font-Poppins-small">
      <footer className="myfooter text-center">
        <div className=" text-center">
          <div className="col-lg-12 text-center">
            <SocialIcon url="https://twitter.com/DecentralgoNet" />{" "}
            <SocialIcon url="https://discord.gg/7wrszzu3Yz" />
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default MyFooter;
