import React from "react";
import { useAsync } from "react-async";

const tokenAddresses = {
  WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
};

const ethers = require("ethers");

const findBalance = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  console.log(provider);
  const signer = await provider.getSigner();
  const account = await signer.getAddress();

  const abi = [
    // Read-Only Functions
    "function balanceOf(address owner) view returns (uint256)",
    "function decimals() view returns (uint8)",
    "function symbol() view returns (string)",

    // Authenticated Functions
    "function transfer(address to, uint amount) returns (bool)",

    // Events
    "event Transfer(address indexed from, address indexed to, uint amount)",
  ];

  // This can be an address or an ENS name
  const address = "0xf7A0383750feF5AbaCe57cc4C9ff98e3790202b3";

  // Read-Only; By connecting to a Provider, allows:
  // - Any constant function
  // - Querying Filters
  // - Populating Unsigned Transactions for non-constant methods
  // - Estimating Gas for non-constant (as an anonymous sender)
  // - Static Calling non-constant methods (as anonymous sender)
  const erc20 = new ethers.Contract(address, abi, provider);

  const balance = await erc20.balanceOf(account).then((result) => {
    return result.toString();
  });
  return balance;
};

const CheckBalance = () => {
  const { data, error } = useAsync({ promiseFn: findBalance });
  console.log(data);
  const decimals = 18;
  const value = data / 10 ** decimals;
  return value;
};

export default CheckBalance;
