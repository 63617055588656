import React from "react";
import { Carousel } from "react-bootstrap";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  ImageWithZoom,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import NFTLocations from "../imgs/NFTS/NFTList.json";

export const NFTCarouselTwo = () => {
  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={1000}
        naturalSlideHeight={1000}
        totalSlides={10}
        visibleSlides={1}
        currentSlide={8}
        isPlaying
        hasMasterSpinner
        interval={2000}
        playDirection="backward"
      >
        <Slider>
          {NFTLocations.map((Location) => (
            <Slide index={Location.number}>
              <Image
                src={require("../imgs/NFTS/" +
                  Location.number.toString() +
                  ".png")}
              />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  );
};
