import React, { Component } from "react";
import getWeb3 from "./getWeb3";
import Buy from "./components/Buy";
import Roadmap from "./components/Roadmap";
import About from "./components/About";
import Whitepaper from "./components/Whitepaper";
import Home from "./components/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

class App extends Component {
  componentDidMount() {
    document.title = "Decentralgo";
  }
  // state = { storageValue: 0, web3: null, accounts: null, contract: null };

  // componentDidMount = async () => {
  //   try {
  //     // Get network provider and web3 instance.
  //     const web3 = await getWeb3();

  //     // Use web3 to get the user's accounts.
  //     const accounts = await web3.eth.getAccounts();

  //     // Get the contract instance.
  //     const networkId = await web3.eth.net.getId();

  //     // Set web3, accounts, and contract to the state, and then proceed with an
  //     // example of interacting with the contract's methods.
  //     this.setState({ web3, accounts }, this.runExample);
  //   } catch (error) {
  //     // Catch any errors for any of the above operations.
  //     alert(
  //       `Failed to load web3, accounts, or contract. Check console for details.`
  //     );
  //     console.error(error);
  //   }
  // };

  render() {
    // if (!this.state.web3) {
    //   return <div>Loading Web3, accounts, and contract...</div>;
    // }

    //return <Navigation />;
    //return <Counter data={this.state} />;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/mint" component={Buy} />
          <Route path="/about" component={About} />
          <Route path="/roadmap" component={Roadmap} />
          <Route path="/whitepaper" component={Whitepaper} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
