import React from "react";
import Navigation from "./Navigation";
import MyFooter from "./MyFooter";
import { Link } from "react-router-dom";
//import { ImageBackground, View, Text } from "react";
import ImageBackground from "react";
import Text from "react";
import Gradient3 from "../imgs/Gradient3.png";
import { Button, Card, Container, Row, Col, Image } from "react-bootstrap";
import { NFTCarousel } from "./NFTCarousel";
const Home = () => {
  return (
    <Container className="home font-Poppins-small">
      <Navigation />
      <Row className="centered">
        <Col className="centeredChild mb-5">
          <h1 className="text-center">Decentralgo</h1>{" "}
          <h3 className="mt-3 text-center">Illuminating the Ether</h3>
          <h5 className="text-muted text-center mt-5">
            {" "}
            Own one of our DecentralgoNet NFT's for lifetime access.{" "}
          </h5>
          <Row className="centeredButtons">
            <Link
              style={{ marginRight: 10 }}
              to="/mint"
              className="mintonebutton btn btn-primary mt-2"
            >
              Mint One
            </Link>{" "}
            <Link to="/about" className="mintonebutton btn btn-secondary mt-2">
              Learn More
            </Link>{" "}
          </Row>
        </Col>
        <Col className="centeredChild">
          {" "}
          <NFTCarousel />
        </Col>
      </Row>

      <MyFooter />
    </Container>
  );
};

export default Home;
