import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import MyFooter from "./MyFooter";

const Navigation = () => {
  return (
    <Container style={{ margin: "0", padding: "0" }}>
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/" className="">
            Decentralgo
          </Navbar.Brand>
          <Nav>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/roadmap">Roadmap</Nav.Link>
            <Nav.Link href="/whitepaper">White Paper</Nav.Link>
            <Nav.Link href="/mint">Mint NFT</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </Container>
  );
};
export default Navigation;
