import React from "react";
import MintNFT from "../scripts/Mint";
import MyFooter from "./MyFooter";
import Navigation from "./Navigation";
import CheckBalance from "../scripts/CheckBalance";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import { NFTCarouselTwo } from "./NFTCarouselTwo";
const Buy = () => {
  return (
    <Container className="home container-1440 font-Poppins-small">
      <Navigation />
      <Row className="centered">
        <Col className="centeredChild">
          <NFTCarouselTwo />
        </Col>
        <Col className="centeredChild mt-5">
          <h1 className="text-center">Season 1 Minting</h1>
          <h3 className="text-center">ETH Per NFT: TBD</h3>
          <h3 className="text-center">Minted: 0/TBD</h3>
          <Row className=" mt-5 centeredButtons">
            <Button
              className="btn-primary"
              style={{ width: "50%", height: "100%" }}
            >
              Coming Soon!
            </Button>
          </Row>
        </Col>
      </Row>
      <MyFooter />
    </Container>
  );
};

export default Buy;
