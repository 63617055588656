import React, { Component } from "react";
import Web3 from "web3";

import returnABI from "./returnABI";

const NFTAddress = "0x79d21eE3363B809D9eE3aBCBfedc2431EfCDC2F2";
const ethers = require("ethers");
const web3 = require("web3");
const { BN } = require("bn.js");

const chainId = 4; //ChainId.MAINNET;

var abi = returnABI();

const MintNFT = async () => {
  console.log("Minting");

  if (typeof window.ethereum !== "undefined") {
    console.log("MetaMask is installed!");
  }

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  console.log(signer);
  const account = await signer.getAddress();
  console.log(account);

  const dNet = new ethers.Contract(NFTAddress, JSON.parse(abi), signer);
  console.log(dNet);
  const etherIn = 0.05;
  const mintNum = 1;
  let overrides = {
    value: ethers.utils.parseEther((mintNum * etherIn).toString()),
  };

  const tx = await dNet.mint(account, mintNum, overrides);
  const receipt = await tx.wait();
  console.log("Transaction receipt");
  console.log(receipt);
};

// render() {
//   return (
//     <div className="container-1440">
//       <Button onClick={this.onBuy}>Mint</Button>
//     </div>
//   );
// }
//}

export default MintNFT;
