import React from "react";
import Navigation from "./Navigation";
import MyFooter from "./MyFooter";
import { Link } from "react-router-dom";
//import { ImageBackground, View, Text } from "react";
import ImageBackground from "react";
import Text from "react";
import Gradient3 from "../imgs/Gradient3.png";
import { Button, Card, Container, Row, Col, Image } from "react-bootstrap";
import { NFTCarousel } from "./NFTCarousel";

const Whitepaper = () => {
  return (
    <Container className="home font-Poppins-small">
      <Navigation />
      <Row className="centered">
        <h1> White Paper</h1>
        <h2 className="centeredChild mt-5"> Introduction</h2>
        <p>
          In a world where the major institutions and their friends have all the
          financial tools to get ahead, the average Joes and Janes struggle with
          their limited resources. This is where Decentralgo comes in.
          Decentralgo's purpose is to provide cutting edge tools to analyze the
          ever expanding cryptoverse. The motivation behind the development of
          this project is to give everyone the tools necessary to succeed in the
          crypto space.
        </p>
        <p>
          Decentralgo will provide a graphical user interface that is intuitive
          and easy to use, but has the capability to do more if you are savvy
          with the platform. In addition, powerful tracking tools and nearly
          unlimited customization will be available to all NFT holders.
        </p>
        <p>
          To help in the launch of Decentralgo we will begin by releasing season
          1 NFTs which will give holders early access to the Decentralgo
          platform. There will be more seasons announced at a later date, so
          stay tuned!
        </p>
        <p>
          Decentralgo will be community driven and we are counting on each and
          every one of you to provide feedback and input on what you want kind
          of cryptoverse analysis tools you would like to see. There will be
          opportunities in the future to contribute to the development of
          Decentralgo and we are very excited to see incredible contributions
          from the community. All NFT holders will have the ability to test and
          utilize the platform before it is released to the public. As the
          project continues to evolve and public demand increases, we will
          slowly open up the platform to a wider audience.
        </p>
      </Row>
      {/*<Row className="centeredButtons">
       <Image src={require("../imgs/sendddd17.png")} />
      </Row>
      */}
      <Row className="centeredChild">
        <h2>DecentralgoNet NFT</h2>
        <p>
          {" "}
          We will release the first wave of our exclusive, ownership NFTs. After
          the first stage of our roadmap is complete, we will mint our first
          season of NFTs. These NFT's will act as a gateway into Decentralgo and
          solidify your stake as a key member within the community.{" "}
          <b>
            <u>Only NFT holders</u>
          </b>{" "}
          will be able to utilize Decentralgo.
        </p>
        <p>
          Each NFT comes with several attributes:
          <ul className="mt-3">Initial Condition: 1 or 2</ul>
          <ul>Algorithm: 0-4</ul>
          <ul>Network Layout: 0-2</ul>
          <ul>Maximum number of Nodes: 10-20</ul>
          <ul>Node Size: 100-300</ul>
          <ul>Edge Size: 5-10</ul>
          <ul>Node Color: 0-19</ul>
          <ul>Edge Color: 0-19</ul>
          <ul>Background Color: 0-19</ul>
          <ul>Number of Edges: Variable</ul>
        </p>
        <p>
          {" "}
          The variability of possible outcomes ensures that there are no two
          networks similar. Although it is possible, it is improbable. This
          variability along with realistic generative algorithms highlights the
          essence of randomness inherent within the cryptoverse.
        </p>
      </Row>
      <Row className="centeredChild">
        <Image
          style={{ opacity: "50%", width: "30%" }}
          src={require("../imgs/BokehPlot1.png")}
        />
        <Image
          style={{ width: "30%" }}
          src={require("../imgs/BokehPlot2.png")}
        />
        <Image
          style={{ opacity: "50%", width: "30%" }}
          src={require("../imgs/BokehPlot3.png")}
        />
      </Row>
      <Row className="centeredChild">
        <h1 className="centeredChild">DecentralgoToken</h1>
        <p>
          {" "}
          DecentralgoToken will be an ERC20 Token issued solely for the purpose
          of monitoring votes and proposals.
        </p>
        <p> More information to be given upon the release of White Paper v2.</p>
      </Row>

      <Row className="centeredChild mt-3">
        <h1 className="centeredChild">Decentralgo - The Platform</h1>
        <p>
          {" "}
          Decentralgo itself will incorporate network science with the
          blockchain. By mapping transactions (edges) between wallets and
          contracts (nodes) we can utilize centralities and network metrics to
          filter out the noise and find the important players. This can also be
          used in assessing a group of nodes that you are interested in (e.g.
          swaps, whales, etc..).{" "}
        </p>
        <p>
          The NFTs will fund the project and allow us to focus resources on
          launching Decentralgo. Once the launch is finished, the community will
          be able to hold the project on its shoulders and carry it to the
          finish line.
        </p>
        <p>
          {" "}
          Stay tuned for White Paper v2 and please follow us on Twitter and join
          our Discord server for the latest information.
        </p>
      </Row>

      <MyFooter />
    </Container>
  );
};

export default Whitepaper;
