import React from "react";
import Navigation from "./Navigation";
import MyFooter from "./MyFooter";
import { Button, Card, Container, Row, Col, Image } from "react-bootstrap";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const Roadmap = () => {
  return (
    <Container className="home font-Poppins-small">
      <Navigation />
      <VerticalTimeline layout="1-column-left" lineColor="grey">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={<b>Stage 1</b>}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html:
                "Release our first season of exclusive, ownership access, NFTs.",
            }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={"Stage 1.1"}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html:
                "Allocation of funds into servers, developers, moderators, and admins.",
            }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={"Stage 1.2"}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html: "Community vote for additional allocation of resources.",
            }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={<b>Stage 2</b>}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html:
                "Initial private launch - showcasing platform capabilities and growth potential to NFT holders.",
            }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={"Stage 2.1"}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html:
                "Release DecentralgoToken - a community feedback protocol.",
            }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={<b>Stage 3</b>}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html:
                "Open a sandbox development kit for users to create utility within Decentralgo.",
            }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={"Stage 3.1"}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html:
                "Community vote on which features will be integrated into Decentralgo.",
            }}
          />
        </VerticalTimelineElement>{" "}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={"Coming soon"}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        >
          <h3
            className="vertical-timeline-element-title"
            dangerouslySetInnerHTML={{
              __html: "More...",
            }}
          />
        </VerticalTimelineElement>
      </VerticalTimeline>
      <MyFooter />
    </Container>
  );
};

export default Roadmap;
