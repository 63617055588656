import React from "react";
import Navigation from "./Navigation";
import MyFooter from "./MyFooter";
import { Link } from "react-router-dom";
//import { ImageBackground, View, Text } from "react";
import ImageBackground from "react";
import Text from "react";
import Gradient3 from "../imgs/Gradient3.png";
import { Button, Card, Container, Row, Col, Image } from "react-bootstrap";
import { NFTCarousel } from "./NFTCarousel";
const About = () => {
  return (
    <Container className="home font-Poppins-small">
      <Navigation />
      <Row style={{ padding: 0, margin: 0 }} className="centeredChild mt-3">
        <Image
          style={{}}
          className="image1"
          src={require("../imgs/Concept_Art2.png")}
        />
      </Row>
      <Row className="centered">
        <Col>
          <h1 className="centeredChild mt-5"> What is Decentralgo?</h1>
          <p>
            Decentralgo was started by only a few, but it will become the many.
            With the help of a broad and diverse community, we hope to
            facilitate the growth of a platform which enriches everyone.
          </p>
          <p>
            The motivation behind the development of this project is to give
            everyone an even playing ground in the cryptoverse. Through the use
            of network science we intend to create a platform where the crypto
            space can be visualized, mapped, and easily traced. Once the initial
            platform is released, we are counting on the community to assist in
            the development and expansion of Decentralgo.
          </p>
          {/*<Card border="dark" className="cardBigBorder font-Poppins-small mb-3">
            <Card.Body>
              The motivation behind the development of this project is to give
              everyone an even playing ground in the cryptoverse. Though the use
              of data science we intend to create a platform where the crypto
              space can be visualized, mapped, and easily traced. Once the
              initial platform is released, we are counting on the community to
              assist in the development and expansion of Decentralgo.
            </Card.Body>
          </Card>
  */}

          <p>
            If you would like to learn more about Decentralgo and our mission,
            please take a moment to read through the white paper and roadmap.
            Also, please follow us on Twitter and Discord for the most recent
            updates.
          </p>
        </Col>
      </Row>
      <Row className="centeredButtons">
        <Link
          style={{ width: "45%", marginRight: 10 }}
          to="/whitepaper"
          className="tfbutton btn btn-primary mt-2"
        >
          White paper
        </Link>{" "}
        <Link
          style={{ width: "45%" }}
          to="/roadmap"
          className="tfbutton btn btn-secondary mt-2"
        >
          Roadmap
        </Link>{" "}
      </Row>
      <MyFooter />
    </Container>
  );
};

export default About;
